




































































































import { Component, Vue } from 'vue-property-decorator';

import CCalendarTest from './c-calendar-test';
import CDatePickerTest from './c-date-picker-test';
import CLoadingTest from './c-loading-test';
import CModalTest from './c-modal-test';
import CPaginationTest from './c-pagination-test';
import CSelectTest from './c-select-test';


const localStorageKey = 'ui-playground-temp/menu';
const compStorageKey = 'ui-playground-temp/comp';


@Component({
    components: {
        'calendar': CCalendarTest,
        'date-picker': CDatePickerTest,
        'loading': CLoadingTest,
        'modal': CModalTest,
        'pagination': CPaginationTest,
        'select-test': CSelectTest
    }
})

export default class UiPlaygroundTemp extends Vue {
    private listener!: () => void;
    private windowWidth = 0;
    private windowHeight = 0;
    private divWidth = 0;
    private divHeight = 0;
    private htmlWidth = 0;
    private htmlHeight = 0;

    private created() {
        this.listener = () => {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;

            const html = document.querySelector('html');
            if (html instanceof HTMLHtmlElement) {
                this.htmlWidth = html.offsetWidth;
                this.htmlHeight = html.offsetHeight;
            }

            const div = document.querySelector('#app');
            if (div instanceof HTMLDivElement) {
                this.divWidth = div.offsetWidth;
                this.divHeight = div.offsetHeight;
            }
        };
        window.addEventListener('resize', this.listener);
        this.listener();

        this.$watch('menu', this.menuChanged);
        this.$watch('comp', this.compChanged);

        const menu = localStorage.getItem(localStorageKey);
        if ((typeof menu === 'string') && (this.menu !== menu)) {
            this.menu = menu;
        }
    }

    private beforeDestroy() {
        window.removeEventListener('resize', this.listener);
    }

    private menu = 'home';
    private menuChanged() {
        localStorage.setItem(localStorageKey, this.menu);
    }

    private comp = 'calendar';
    private compChanged() {
        localStorage.setItem(compStorageKey, this.comp);

        console.log('compStorageKey = ', this.comp);
    }
}

